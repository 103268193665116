<template>
    <div class="dhHotRec">
        <div class="dhrBox">
            <!-- 热门推荐 -->
            <div class="drb1">
                <div class="drbTop">热门推荐</div>
                <div class="db1List">
                    <ul>
                        <li class="d1Li" v-for="item in 8" :key="item">
                            <div class="d1lBox">
                                <div class="dlbIcon">
                                    <img src="http://iv.vu818.com/img/vubq.png" alt="" />
                                </div>
                                <p class="dlbP">.com</p>
                                <p class="dlbP2">网络服务首选经典域名，新用户25元</p>
                                <div class="dlbI">新用户专项</div>
                                <div class="dlbB">
                                    <p class="dbP">威有25/首年起</p>
                                    <p class="dbP">原价威有80/年</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 域名交易 -->
            <div class="drb2">
                <div class="drb2Top">
                    <p class="d2t1">域名交易</p>
                    <div class="d2t2">
                        <p class="d2tP">为买卖双方提供丰富、安全、高效、便携的域名交易服务</p>
                        <p class="d2tA" @click="getMore">查看更多<i class="iconfont icon-youbianjiantou"></i></p>
                    </div>
                </div>
                <div class="drb2List">
                    <ul>
                        <li class="d2lLi" v-for="item in 10" :key="item" @click="goDetail">
                            <p class="d2l1">24hr.live</p>
                            <div class="d2l2">白金域名</div>
                            <p class="d2l3">威有961.04</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dhHotRec',
    methods: {
        goDetail() {
            this.$router.push('/dmDetail');
        },
        getMore() {
            this.$router.push('dmLists');
        },
    },
};
</script>

<style lang="less" scoped>
.dhHotRec {
    width: 100%;
    height: 1300px;
    margin-top: 50px;
    .dhrBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        background-color: #fff;
        // 热门推荐
        .drb1 {
            width: 100%;
            height: 700px;
            .drbTop {
                width: 50%;
                height: 40px;
                line-height: 40px;
                margin: 0 auto;
                font-size: 28px;
                text-align: center;
            }
            .db1List {
                width: 100%;
                height: 500px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .d1Li {
                        width: 23%;
                        height: 48%;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        transition: 0.5s;
                        .d1lBox {
                            width: 90%;
                            height: 75%;
                            margin: 0 auto;
                            margin-top: 10%;
                            position: relative;
                            .dlbIcon {
                                width: 60px;
                                height: 30px;
                                position: absolute;
                                left: 95px;
                                top: 10px;
                            }
                            .dlbP {
                                width: 100%;
                                height: 36px;
                                line-height: 36px;
                                font-size: 36px;
                            }
                            .dlbP2 {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 14px;
                                color: gray;
                                margin-top: 10px;
                            }
                            .dlbI {
                                width: 78px;
                                height: 20px;
                                margin-top: 10px;
                                line-height: 20px;
                                text-align: center;
                                border: 1px solid #7781f1;
                                border-radius: 5px;
                                font-size: 12px;
                                color: #7781f1;
                            }
                            .dlbB {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                margin-top: 20px;
                                .dbP {
                                    font-size: 20px;
                                    float: left;
                                }
                                .dbP:nth-child(2) {
                                    line-height: 35px;
                                    font-size: 14px;
                                    margin-left: 10px;
                                    text-decoration: line-through;
                                    color: gray;
                                }
                            }
                        }
                    }
                    .d1Li:hover {
                        box-shadow: 1px 1px 10px 1px gray;
                        transform: translateY(-20px);
                    }
                }
            }
        }
        // 域名交易
        .drb2 {
            width: 100%;
            height: 600px;
            .drb2Top {
                width: 50%;
                height: 75px;
                text-align: center;
                margin: 0 auto;
                .d2t1 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 28px;
                }
                .d2t2 {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 14px;
                    .d2tP {
                        color: gray;
                        float: left;
                        margin-left: 15%;
                    }
                    .d2tA {
                        margin-left: 5%;
                        float: left;
                        color: #7781f1;
                        cursor: pointer;
                    }
                }
            }
            .drb2List {
                width: 100%;
                height: 450px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .d2lLi {
                        width: 18%;
                        height: 47%;
                        transition: 0.5s;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        .d2l1 {
                            width: 90%;
                            height: 40px;
                            line-height: 40px;
                            font-size: 26px;
                            margin: 0 auto;
                            margin-top: 5%;
                        }
                        .d2l2 {
                            width: 65px;
                            height: 20px;
                            line-height: 20px;
                            border-radius: 5px;
                            color: white;
                            font-size: 12px;
                            text-align: center;
                            margin-left: 5%;
                            margin-top: 5%;
                            background-color: #7781f1;
                        }
                        .d2l3 {
                            width: 90%;
                            height: 35px;
                            color: #7781f1;
                            font-size: 28px;
                            margin-left: 5%;
                            margin-top: 20%;
                        }
                    }
                    .d2lLi:hover {
                        box-shadow: 1px 1px 10px 1px gray;
                        transform: translateY(-20px);
                    }
                }
            }
        }
    }
}
</style>
